import React, { useMemo } from "react";
import {
  MimaButton,
  MimaInput,
  MimaMultiDropdown,
  MimaTagInput,
  MimaText,
  ReceiptCheckBox as RCB,
} from "../../../components";
import { Field, FieldArray } from "formik";
import { colorsArray } from "../../../utils/utils";
import { businessStore } from "../../../stores";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};
const FieldTagInput = ({ field, ...props }) => {
  return <MimaTagInput {...field} {...props} />;
};

const StyleStep2 = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  const sizeSuggestion = useMemo(() => {
    const sizes = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
    const shoeSizes = ["5", "6", "7", "8", "9", "10", "11", "12", "13"];
    const clothingSizes = ["28", "30", "32", "34", "36", "38", "40", "42"];

    const suggest = [...sizes, ...shoeSizes, ...clothingSizes];

    return suggest.map((x) => ({
      a: x,
    }));
  }, []);

  const newColorsArray = colorsArray.map((x) => {
    return { key: x.code, value: x.color };
  });

  const localCurrency = businessStore.localCurrency;
  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" ml={2} mb={1}>
          Pricing Model
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Fixed Price"
            name="pricingModel"
            onChange={() => {
              setFieldValue("pricingModel", "FIXED_PRICE");
            }}
            checked={values.pricingModel === "FIXED_PRICE"}
            value="FIXED_PRICE"
          />
          <RCB
            title="Bespoke"
            onChange={() => {
              setFieldValue("pricingModel", "BESPOKE");
            }}
            checked={values.pricingModel === "BESPOKE"}
            name="pricingModel"
            value="BESPOKE"
          />
        </div>
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" mb={1} mt={1} ml={2}>
          Is this Product's Pricing variable?
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Yes"
            onChange={() => {
              setFieldValue("hasVariations", true);
            }}
            checked={values.hasVariations}
            value={values.hasVariations}
            name="hasVariations"
          />
          <RCB
            title="No"
            onChange={() => {
              setFieldValue("hasVariations", false);
            }}
            checked={!values.hasVariations}
            value={!values.hasVariations}
            name="hasVariations"
          />
        </div>
      </div>
      <FieldArray name="variations">
        {({ push, remove }) => (
          <React.Fragment>
            {values.variations.map((_, i) => (
              <div key={i}>
                {values?.hasVariations ? (
                  <MimaText
                    variant="smallBold"
                    color="var(--color-primary)"
                    mb={2}
                    style={{
                      borderBottom: "1px solid var(--color-primary)",
                      width: "fit-content",
                      paddingBottom: "3px",
                    }}
                  >
                    Variation {i + 1}
                  </MimaText>
                ) : (
                  ""
                )}
                <Field
                  name={`variations.${i}.name`}
                  id={`variations.${i}.name`}
                  component={FieldInput}
                  type="text"
                  placeholder="Variation name"
                  labelTitle="Variation name"
                  mb={2}
                />
                <div style={{ marginBottom: "2rem" }}>
                  <MimaText variant="small" mb={1} mt={1}>
                    Set as out of stock
                  </MimaText>
                  <div className="modal__beside__grid">
                    <RCB
                      title="Yes"
                      onChange={() => {
                        setFieldValue(`variations.${i}.isSoldOut`, true);
                      }}
                      checked={values?.variations[i]?.isSoldOut}
                      value={values?.variations[i]?.isSoldOut}
                      name={`variations.${i}.isSoldOut`}
                    />
                    <RCB
                      title="No"
                      onChange={() => {
                        setFieldValue(`variations.${i}.isSoldOut`, false);
                      }}
                      checked={!values?.variations[i]?.isSoldOut}
                      value={!values?.variations[i]?.isSoldOut}
                      name={`variations.${i}.isSoldOut`}
                    />
                  </div>
                </div>
                <Field
                  name={`variations.${i}.sizes`}
                  component={FieldTagInput}
                  labelTitle="Sizes (optional)"
                  placeholder="Enter Sizes"
                  tags={values.variations[i].sizes}
                  setTags={(item) => {
                    setFieldValue(`variations.${i}.sizes`, item);
                  }}
                  onBlur={handleBlur}
                  error={errors?.variations?.[i]?.sizes}
                  touched={touched?.variations?.[i]?.sizes}
                  width={38}
                  mt={2}
                  suggestionData={sizeSuggestion}
                  handleFilter={(item, text) => {
                    return item?.a?.toLowerCase()?.includes(text);
                  }}
                />
                <MimaTagInput
                  placeholder="Add Accessories Types"
                  labelTitle="Accessory Informations (optional)"
                  tags={values.variations[i].accessories}
                  setTags={(item) => {
                    setFieldValue(`variations.${i}.accessories`, item);
                  }}
                  name={`variations.${i}.accessories`}
                  onBlur={handleBlur}
                  error={errors?.variations?.[i]?.accessories}
                  touched={touched?.variations?.[i]?.accessories}
                  width={38}
                  mt={2}
                  // suggestionData={collectionSuggestion}
                  // handleFilter={(item, text) => {
                  //   return item?.a?.toLowerCase()?.includes(text);
                  // }}
                />
                <MimaMultiDropdown
                  labelTitle="Style Colors (optional)"
                  name={`variations.${i}.colorsAvailable`}
                  id={`variations.${i}.colorsAvailable`}
                  value={values?.variations[i]?.colorsAvailable?.map((x) => ({
                    value: x.color,
                    key: x.code,
                  }))}
                  placeholder="Select Colors"
                  currentValue={values?.variations[i]?.colorsAvailable?.map(
                    (x) => ({
                      value: x.color,
                      key: x.code,
                    })
                  )}
                  onChange={(data) => {
                    const newData = data?.map((x) => ({
                      color: x.value,
                      type: "PRIMARY",
                      code: x.key,
                    }));
                    setFieldValue(`variations.${i}.colorsAvailable`, newData);
                  }}
                  data={newColorsArray}
                  touched={touched?.variations?.[i]?.colorsAvailable}
                  error={errors?.variations?.[i]?.colorsAvailable}
                  mt={2}
                  mb={2}
                />
                <div style={{ marginBottom: "2rem" }}>
                  <MimaText variant="small" mb={1}>
                    Add Custom Colors or Color combinations
                  </MimaText>
                  <div className="modal__beside__grid">
                    <RCB
                      title="Yes"
                      onChange={() => {
                        setFieldValue(`variations.${i}.addCustomColor`, true);
                      }}
                      checked={values?.variations?.[i]?.addCustomColor}
                      value={values?.variations?.[i]?.addCustomColor}
                      name={`variations.${i}.addCustomColor`}
                    />
                    <RCB
                      title="No"
                      onChange={() => {
                        setFieldValue(`variations.${i}.addCustomColor`, false);
                      }}
                      checked={!values?.variations?.[i]?.addCustomColor}
                      value={!values?.variations?.[i]?.addCustomColor}
                      name={`variations.${i}.addCustomColor`}
                    />
                  </div>
                </div>
                {values?.variations?.[i]?.addCustomColor ? (
                  <MimaTagInput
                    placeholder="Enter custom colors"
                    labelTitle="Custom Colors"
                    tags={values.variations[i].customColors}
                    setTags={(custom) => {
                      setFieldValue(`variations.${i}.customColors`, custom);
                    }}
                    name={`variations.${i}.customColors`}
                    onBlur={handleBlur}
                    error={errors?.variations?.[i]?.customColors}
                    touched={touched?.variations?.[i]?.customColors}
                    width={38}
                    mt={2}
                    mb={2}
                    // suggestionData={collectionSuggestion}
                    // handleFilter={(item, text) => {
                    //   return item?.a?.toLowerCase()?.includes(text);
                    // }}
                  />
                ) : (
                  ""
                )}
                <Field
                  name={`variations.${i}.location`}
                  id={`variations.${i}.location`}
                  component={FieldInput}
                  type="text"
                  placeholder="Location"
                  labelTitle="Location (optional)"
                  mb={2}
                />
                {values?.type === "SERVICE" ? (
                  <Field
                    name={`variations.${i}.durationInMinutes`}
                    id={`variations.${i}.durationInMinutes`}
                    component={FieldInput}
                    type="number"
                    placeholder="Duration In Minutes"
                    labelTitle="Duration In Minutes"
                    mb={2}
                    error={errors?.variations?.[i]?.durationInMinutes}
                    touched={touched?.variations?.[i]?.durationInMinutes}
                  />
                ) : (
                  ""
                )}

                {values.pricingModel === "FIXED_PRICE" ? (
                  <>
                    <MimaText mt={2} mb={2} variant="smallBold">
                      Selling Price in {localCurrency}
                    </MimaText>
                    <div className="modal__beside__grid">
                      <Field
                        name={`variations.${i}.local.withMaterial`}
                        id={`variations.${i}.local.withMaterial`}
                        component={FieldInput}
                        type="number"
                        placeholder={
                          values.type === "SERVICE"
                            ? "Price"
                            : "Ready to wear (rtw)"
                        }
                        labelTitle={
                          values.type === "SERVICE"
                            ? "Price"
                            : "Ready to wear (rtw)"
                        }
                        variant="form"
                        error={errors?.variations?.[i]?.local?.withMaterial}
                        touched={touched?.variations?.[i]?.local?.withMaterial}
                      />
                      <Field
                        name={`variations.${i}.local.withoutMaterial`}
                        id={`variations.${i}.local.withoutMaterial`}
                        component={FieldInput}
                        type="number"
                        placeholder="Price With Material"
                        labelTitle="Price With Material"
                        variant="form"
                      />
                    </div>
                    <Field
                      name={`variations.${i}.local.onSaleSlashPrice`}
                      id={`variations.${i}.local.onSaleSlashPrice`}
                      component={FieldInput}
                      type="number"
                      placeholder={
                        values.type === "SERVICE"
                          ? "Promo Price"
                          : "RTW Promo Price"
                      }
                      labelTitle={
                        values.type === "SERVICE"
                          ? "Promo Price"
                          : "RTW Promo Price"
                      }
                      mb={2}
                    />

                    <MimaText variant="smallBold" mb={1} mt={1}>
                      Add USD Selling Price
                    </MimaText>
                    <div className="modal__beside__grid">
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("sellAbroad", true);
                          setFieldValue(
                            `variations.${i}.international.currencyCode`,
                            "USD"
                          );
                        }}
                        checked={values.sellAbroad}
                        value={values.sellAbroad}
                        name="sellAbroad"
                      />
                      <RCB
                        title="No"
                        onChange={() => {
                          setFieldValue("sellAbroad", false);
                        }}
                        checked={!values.sellAbroad}
                        value={!values.sellAbroad}
                        name="sellAbroad"
                      />
                    </div>
                    {values.sellAbroad ? (
                      <div className="modal__beside__grid">
                        <Field
                          name={`variations.${i}.international.withMaterial`}
                          id={`variations.${i}.international.withMaterial`}
                          component={FieldInput}
                          type="number"
                          placeholder="Price"
                          labelTitle="Price"
                          variant="form"
                          error={
                            errors?.variations?.[i]?.international?.withMaterial
                          }
                          touched={
                            touched?.variations?.[i]?.international
                              ?.withMaterial
                          }
                        />
                        <Field
                          name={`variations.${i}.international.onSaleSlashPrice`}
                          id={`variations.${i}.international.onSaleSlashPrice`}
                          component={FieldInput}
                          type="number"
                          placeholder="Promo Price"
                          labelTitle="Promo Price"
                          variant="form"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {i !== 0 ? (
                  <MimaButton
                    title="Remove Variant"
                    buttonColor="var(--color-error)"
                    titleColor="var(--color-white)"
                    onClick={() => remove(i)}
                    mb={2}
                  />
                ) : (
                  ""
                )}

                <hr
                  className="fullWidth__border"
                  style={{ marginBottom: "2rem" }}
                />
              </div>
            ))}

            {values.hasVariations ? (
              <MimaButton
                title="Add Another Variant"
                variant="outlined"
                type="button"
                onClick={() =>
                  push({
                    sizes: [],
                    name: "",
                    isSoldOut: false,
                    accessories: [],
                    addCustomColor: false,
                    customColors: [],
                    colorsAvailable: [
                      {
                        color: "",
                        type: "PRIMARY",
                        code: "",
                      },
                    ],
                    location: "",
                    local: {
                      withoutMaterial: 0,
                      currencyCode: businessStore.localCurrency || "NGN",
                      onSaleSlashPrice: 0,
                      withMaterial: 0,
                    },
                    durationInMinutes: 0,
                    international: {
                      currencyCode: values?.sellAbroad ? "USD" : "",
                      withMaterial: 0,
                      onSaleSlashPrice: 0,
                    },
                  })
                }
              />
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </>
  );
};

export default StyleStep2;
