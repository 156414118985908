import React from "react";
import {
  IconButton,
  MimaButton,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import { Formik } from "formik";
import useEditStyleLogic from "./useLogic/useEditStyleLogic";

const EditStyle = ({ closeModal, refetch }) => {
  const {
    onSubmit,
    validationSchema,
    loading,
    selectedStyleData,
    renderStep,
    initialValues,
    currentStep,
    setCurrentStep,
    loadingCollections,
  } = useEditStyleLogic({ closeModal, refetch });

  const stepTitles = [
    "General Product Information",
    "Product Pricing & Variations",
    "Images & Delivery",
  ];

  const goBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {loadingCollections ? (
        <PageLoader />
      ) : (
        <>
          <MimaText
            variant="subtitleBold"
            align="center"
            color="var(--color-dark)"
          >
            Edit Style - {selectedStyleData?.name}
          </MimaText>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div style={{ width: "38rem" }}>
                <MimaText align="center" mb={2}>
                  {stepTitles[currentStep - 1]}
                </MimaText>
                {currentStep > 1 && (
                  <div
                    onClick={goBack}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: "2rem 0",
                    }}
                  >
                    <IconButton variant="back" />{" "}
                    <MimaText variant="small" ml={-1.5}>
                      Go Back
                    </MimaText>
                  </div>
                )}
                {renderStep(
                  currentStep,
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  setFieldValue
                )}
                <MimaButton
                  title={currentStep < 3 ? "Continue" : "Save Edit"}
                  loading={loading}
                  variant="regular"
                  type="submit"
                  onClick={handleSubmit}
                  width={38}
                  mt={2}
                />
              </div>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default EditStyle;
