import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { businessStore } from "../stores";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { queryClient } from "../App";

export const GET_ALL_MEASUREMENTS = "get-all-measurements";

const useGetMeasurements = (
  measurementTableQuery,
  filterQuery,
  searchQuery
) => {
  const id = businessStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${measurementTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${measurementTableQuery}&${filterQuery}`;
    } else {
      return measurementTableQuery;
    }
  }, [filterQuery, measurementTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, GET_ALL_MEASUREMENTS, query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/measurement/business?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};

const uploadDocument = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/businesses/documents/upload",
    method: "post",
    payload,
    hasImageUpload: true,
  });
  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status, data };
};

const takeMeasurement = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/fashion/measurement",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    queryClient.invalidateQueries({ queryKey: [GET_ALL_MEASUREMENTS] });

    return { status };
  }
  return { status, errorMessage };
};

const editMeasurement = async (payload, measurementId, bId) => {
  const query = `bId=${bId}&measurementId=${measurementId}`;
  const { status, errorMessage } = await apiRequest({
    url: `/fashion/measurement?${query}`,
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    await queryClient.invalidateQueries({ queryKey: [GET_ALL_MEASUREMENTS] });

    return { status };
  }
  return { status, errorMessage };
};

const MeasurementController = {
  useGetMeasurements,
  uploadDocument,
  takeMeasurement,
  editMeasurement,
};

export default MeasurementController;
