import React, { useMemo } from "react";
import {
  MimaInput,
  MimaMultiDropdown,
  MimaQuillInput,
  MimaTagInput,
  MimaText,
  ReceiptCheckBox as RCB,
} from "../../../components";
import styles from "../../../assets/styles/website/website.module.scss";
import { websiteStore } from "../../../stores";

const StyleStep1 = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  const collections = websiteStore?.collections;
  const collectionsData = useMemo(() => {
    return collections.map((x) => ({
      key: x.key,
      value: x.name,
    }));
  }, [collections]);

  const collectionSuggestion = useMemo(() => {
    return collections.map((x) => ({
      a: x.name,
    }));
  }, [collections]);

  return (
    <>
      <MimaInput
        type="text"
        labelTitle="Product Name"
        placeholder="Enter Product Name"
        name="name"
        mb={2}
        value={values.name}
        onChange={(e) => {
          handleChange(e);
          if (values.variations.length === 1) {
            setFieldValue(`variations.${[0]}.name`, `${e.target.value}`);
          }
        }}
        onBlur={handleBlur}
        error={errors.name}
        touched={touched.name}
      />

      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" mb={1} mt={1}>
          Product Type
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Product"
            onChange={() => {
              setFieldValue("type", "PRODUCT");
            }}
            checked={values.type === "PRODUCT"}
            value={"PRODUCT"}
            name="type"
          />
          <RCB
            title="Service"
            onChange={() => {
              setFieldValue("type", "SERVICE");
            }}
            checked={values.type === "SERVICE"}
            value={"SERVICE"}
            name="type"
          />
        </div>
      </div>

      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" mb={1} mt={1}>
          Set as best selling
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Yes"
            onChange={() => {
              setFieldValue("bestSelling", true);
            }}
            checked={values.bestSelling}
            value={values.bestSelling}
            name="bestSelling"
          />
          <RCB
            title="No"
            onChange={() => {
              setFieldValue("bestSelling", false);
            }}
            checked={!values.bestSelling}
            value={!values.bestSelling}
            name="bestSelling"
          />
        </div>
      </div>

      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" mb={1} mt={1}>
          Pin Style To Top
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Yes"
            onChange={() => {
              setFieldValue("topView", true);
            }}
            checked={values.topView}
            value={values.topView}
            name="topView"
          />
          <RCB
            title="No"
            onChange={() => {
              setFieldValue("topView", false);
            }}
            checked={!values.topView}
            value={!values.topView}
            name="topView"
          />
        </div>
      </div>

      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small" mb={1}>
          Do You Want To Create New Collection ?
        </MimaText>
        <div className="modal__beside__grid">
          <RCB
            title="Yes"
            onChange={() => {
              setFieldValue("createCollections", true);
            }}
            checked={values.createCollections}
            value={values.createCollections}
            name="createCollections"
          />
          <RCB
            title="No"
            onChange={() => {
              setFieldValue("createCollections", false);
            }}
            checked={!values.createCollections}
            value={!values.createCollections}
            name="createCollections"
          />
        </div>
        {errors.createCollections ? (
          <MimaText variant="small" color="var(--color-error)">
            {""}
            {touched.createCollections && errors.createCollections}
            {""}
          </MimaText>
        ) : null}

        {!values.createCollections ? (
          <>
            <MimaMultiDropdown
              labelTitle="Style Collection"
              name="styleCollections"
              id="styleCollections"
              value={values.styleCollections.map((x) => ({
                key: x,
                value: x,
              }))}
              placeholder="Select Collection"
              currentValue={values.styleCollections.map((x) => ({
                key: x,
                value: x,
              }))}
              onChange={(data) => {
                const newData = data?.map((x) => x.key);
                setFieldValue("styleCollections", newData);
              }}
              data={collectionsData}
              touched={touched.styleCollections}
              error={errors.styleCollections}
              mt={2}
            />
          </>
        ) : (
          <MimaTagInput
            placeholder="Style Collection"
            labelTitle="Enter New Collection"
            tags={values.newCollections}
            setTags={(item) => {
              setFieldValue("newCollections", item);
            }}
            name="styleCollections"
            onBlur={handleBlur}
            error={errors.newCollections}
            touched={touched.newCollections}
            width={38}
            mt={2}
            suggestionData={collectionSuggestion}
            handleFilter={(item, text) => {
              return item?.a?.toLowerCase()?.includes(text);
            }}
          />
        )}
      </div>

      <MimaQuillInput
        name="description"
        labelTitle="Description"
        value={values.description}
        onChange={(content) => setFieldValue("description", content)}
        error={errors.description}
        touched={touched.description}
        placeholder={`Enter Description`}
        mb={2}
      />

      <div style={{ marginBottom: "2rem" }}>
        <MimaText variant="small">Gender</MimaText>
        <div className={styles.checks}>
          <RCB
            title="Male"
            id="MALE"
            name="gender"
            onChange={() => {
              setFieldValue("gender", "MALE");
            }}
            checked={values.gender === "MALE"}
            value="Male"
          />
          <RCB
            title="Female"
            onChange={() => {
              setFieldValue("gender", "FEMALE");
            }}
            checked={values.gender === "FEMALE"}
            id="FEMALE"
            name="gender"
            value="Female"
          />
          <RCB
            title="Unisex"
            id="UNISEX"
            onChange={() => {
              setFieldValue("gender", "UNISEX");
            }}
            checked={values.gender === "UNISEX"}
            name="gender"
            value="UNISEX"
          />
        </div>
      </div>
    </>
  );
};

export default StyleStep1;
