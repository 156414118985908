import React from "react";
import {
  MimaDropZone,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
} from "../../../components";

const StyleStep3 = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  const availableDays = [
    "EVERYDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  return (
    <>
      <MimaDropZone
        title="Upload Product Images"
        info="maximum image Size 1MB"
        maxNumOfImages={10}
        files={values.imageUrls}
        setFiles={(newFiles) => {
          setFieldValue("imageUrls", newFiles);
        }}
        labelTitle="Upload Images"
        name="imageUrls"
        errorMsg={errors.imageUrls}
      />

      {values.type === "SERVICE" ? (
        <>
          <div style={{ marginBottom: "2rem" }}>
            <MimaText variant="smallBold" mb={0.5} mt={1}>
              Set service availability hours between 1 and 24.
            </MimaText>
            <div className="modal__beside__grid">
              <MimaInput
                type="number"
                labelTitle="Start Hours"
                name={`availableWindow.startHour`}
                id={`availableWindow.startHour`}
                value={values.availableWindow.startHour}
                onChange={handleChange}
                error={errors?.availableWindow?.startHour}
                touched={touched?.availableWindow?.startHour}
                variant="form"
              />

              <MimaInput
                type="number"
                labelTitle="End Hours"
                name={`availableWindow.endHour`}
                id={`availableWindow.endHour`}
                value={values.availableWindow.endHour}
                onChange={handleChange}
                error={errors?.availableWindow?.endHour}
                touched={touched?.availableWindow?.endHour}
                onBlur={handleBlur}
                variant="form"
              />
            </div>
          </div>

          <div style={{ marginBottom: "2rem" }}>
            <MimaText variant="smallBold" mb={1} mt={1}>
              Set Days for which your services are available
            </MimaText>
            <div className="modal__beside__grid">
              {availableDays.map((x, i) => (
                <React.Fragment key={i}>
                  <RCB
                    title={x}
                    onChange={() => {
                      let newDays;

                      if (values.availableDays.includes(x)) {
                        newDays = values.availableDays.filter((y) => y !== x);
                        return setFieldValue("availableDays", newDays);
                      }

                      if (x === "EVERYDAY") {
                        newDays = [...availableDays];
                        return setFieldValue("availableDays", newDays);
                      }

                      newDays = [...values.availableDays, x];
                      setFieldValue("availableDays", newDays);
                    }}
                    checked={values.availableDays.includes(x)}
                    value={x}
                    name="availableDays"
                  />
                </React.Fragment>
              ))}
            </div>
            {errors.availableDays ? (
              <MimaText
                variant="small"
                color="var(--color-error)"
                mb={1}
                mt={1}
              >
                {errors.availableDays}
              </MimaText>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <div style={{ marginBottom: "2rem" }}>
          <MimaText variant="smallBold" mb={0.5} mt={1}>
            Add Delivery Duration
          </MimaText>
          <div className="modal__beside__grid">
            <MimaInput
              type="number"
              labelTitle="Min Est. Duration"
              name={`daysRequiredForDelivery.early`}
              id={`daysRequiredForDelivery.early`}
              value={values.daysRequiredForDelivery.early}
              onChange={handleChange}
              error={errors?.daysRequiredForDelivery?.early}
              touched={touched?.daysRequiredForDelivery?.early}
              variant="form"
            />

            <MimaInput
              type="number"
              labelTitle="Max Est. Duration"
              name={`daysRequiredForDelivery.late`}
              id={`daysRequiredForDelivery.late`}
              value={values.daysRequiredForDelivery.late}
              onChange={handleChange}
              error={errors?.daysRequiredForDelivery?.late}
              touched={touched?.daysRequiredForDelivery?.late}
              onBlur={handleBlur}
              variant="form"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StyleStep3;
