import React from "react";
import { DangerousContent, MimaText, Modal } from "../../components";
import { websiteStore } from "../../stores";
import styles from "../../assets/styles/website/website.module.scss";
import { amountFormatter } from "../../utils/utils";
import moment from "moment";

const ViewMoreStyle = ({ closeModal }) => {
  const selectedStyleData = websiteStore.selectedStyle;
  //   let className = styles.imageGrid__item;

  //   if (index % 4 === 0 || index % 4 === 1) {
  //     // Indices 0, 1, 4, 5, etc. should be full-size (large)
  //     className = `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
  //   } else {
  //     // Indices 2, 3, 6, 7, etc. should be half-size (c, d; e, f)
  //     className = `${styles.imageGrid__item} ${styles.imageGrid__item__half}`;
  //   }

  //   return (
  //     <div key={index} className={className}>
  //       <img src={image} alt="product" />
  //     </div>
  //   );
  // });

  const renderImageGrid = (images) => {
    return images.map((image, index) => {
      let className = styles.imageGrid__item;
      const totalImages = images.length;

      if (totalImages <= 3) {
        // Case for 3 or fewer images: all full size
        className = `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
      } else if (totalImages === 4) {
        // Case for 4 images: first 2 large, last 2 half
        if (index < 2) {
          className = `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
        } else {
          className = `${styles.imageGrid__item} ${styles.imageGrid__item__half}`;
        }
      } else if (totalImages >= 5 && totalImages <= 7) {
        // Case for 5-7 images: row 1 follows 4-image rule, row 2 is full images
        if (index < 4) {
          className =
            index < 2
              ? `${styles.imageGrid__item} ${styles.imageGrid__item__large}`
              : `${styles.imageGrid__item} ${styles.imageGrid__item__half}`;
        } else {
          className = `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
        }
      } else if (totalImages >= 8) {
        // Case for 8 or more images: alternate row 1 and row 2 with flipped layout
        if (index < 4) {
          // Row 1: follow the 4-image rule
          className =
            index < 2
              ? `${styles.imageGrid__item} ${styles.imageGrid__item__large}`
              : `${styles.imageGrid__item} ${styles.imageGrid__item__half}`;
        } else if (index < 8) {
          // Row 2: mirror the 4-image rule
          className =
            index % 4 < 2
              ? `${styles.imageGrid__item} ${styles.imageGrid__item__half}`
              : `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
        } else {
          // Additional rows: handle as full-size images
          className = `${styles.imageGrid__item} ${styles.imageGrid__item__large}`;
        }
      }

      return (
        <div key={index} className={className}>
          <img src={image} alt="product" />
        </div>
      );
    });
  };

  const sortDaysOfWeek = (daysArray) => {
    const daysOfWeekOrder = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];

    const correctOrder = daysArray.sort((a, b) => {
      return daysOfWeekOrder.indexOf(a) - daysOfWeekOrder.indexOf(b);
    });

    return correctOrder;
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="modal__w38">
        <MimaText
          variant="subtitleBold"
          color="var(--color-fash-primary-3)"
          align="center"
          mb={3}
        >
          {selectedStyleData?.name}
        </MimaText>

        <MimaText variant="small" mb={0.5}>
          Images
        </MimaText>
        <div className={styles.imageGrid}>
          {renderImageGrid(selectedStyleData?.imageUrls)}
        </div>

        <MimaText labelTitle="Product Name" mb={2}>
          {selectedStyleData?.name}
        </MimaText>
        <MimaText labelTitle="Description" mt={2} mb={2}>
          <DangerousContent content={selectedStyleData?.description || ""} />
        </MimaText>

        <div className="modal__beside__grid">
          <MimaText labelTitle="Type">{selectedStyleData?.type}</MimaText>
          <MimaText labelTitle="Gender">{selectedStyleData?.gender}</MimaText>
          <MimaText labelTitle="Pricing Model">
            {selectedStyleData?.pricingModel}
          </MimaText>
          <MimaText labelTitle="Pin To Top">
            {selectedStyleData?.topView ? "Yes" : "No"}
          </MimaText>
          <MimaText labelTitle="Is Best Selling">
            {selectedStyleData?.bestSelling ? "Yes" : "No"}
          </MimaText>
          <MimaText labelTitle="Delivery duration">
            {selectedStyleData?.daysRequiredForDelivery?.early} -{" "}
            {selectedStyleData?.daysRequiredForDelivery?.late} days
          </MimaText>
          {selectedStyleData?.type === "SERVICE" ? (
            <>
              <MimaText labelTitle="Open Hours">
                {moment(
                  `${selectedStyleData?.availableWindow?.startHour}`,
                  "H"
                ).format("hA")}{" "}
                -{" "}
                {moment(
                  `${selectedStyleData?.availableWindow?.endHour}`,
                  "H"
                ).format("hA")}{" "}
              </MimaText>
              <MimaText labelTitle="Open days">
                {sortDaysOfWeek(selectedStyleData?.availableDays)?.map(
                  (x, i) => {
                    const isLastItem =
                      i ===
                      sortDaysOfWeek(selectedStyleData?.availableDays)?.length -
                        1;
                    return (
                      <span key={i}>
                        {x}
                        {isLastItem ? "." : ","}{" "}
                      </span>
                    );
                  }
                )}
              </MimaText>
            </>
          ) : (
            ""
          )}
        </div>

        <MimaText labelTitle="Collection Details " mt={2} mb={2}>
          {selectedStyleData?.styleCollections.map((x, i) => {
            const isLastItem =
              i === selectedStyleData?.styleCollections.length - 1;
            return (
              <span key={i}>
                {x}
                {isLastItem ? "." : ","}{" "}
              </span>
            );
          })}
        </MimaText>

        <MimaText mt={2} variant="smallBold">
          Variations & Pricing
        </MimaText>
        {selectedStyleData?.pricingModel === "FIXED_PRICE" ? (
          <>
            {selectedStyleData?.variations.map((variant, i) => (
              <div className="fullWidth__border" key={i}>
                <MimaText variant="smallBold" mb={0.5} mt={2}>
                  Variant {i + 1}
                </MimaText>
                <div className="modal__beside__grid">
                  <MimaText labelTitle="Name">{variant?.name}</MimaText>
                  <MimaText labelTitle="Availability">
                    {variant?.isSoldOut ? "Sold out" : "In Stock"}
                  </MimaText>
                  <MimaText labelTitle="Sizes">
                    {variant?.sizes?.length > 0
                      ? variant?.sizes?.map((item, i) => (
                          <div key={i}>{item}</div>
                        ))
                      : "N/A"}
                  </MimaText>
                  <MimaText labelTitle="Colors">
                    {variant?.colorsAvailable?.length > 0
                      ? variant?.colorsAvailable?.map((item, i) => (
                          <div key={i}>{item?.color}</div>
                        ))
                      : "N/A"}
                  </MimaText>
                  <MimaText labelTitle="Accessories">
                    {variant?.accessories?.length > 0
                      ? variant?.accessories?.map((item, i) => (
                          <div key={i}>{item}</div>
                        ))
                      : "N/A"}
                  </MimaText>
                </div>
                {variant?.sellingPrices?.length > 0 ? (
                  <>
                    {variant?.sellingPrices?.map((price, i) => (
                      <div key={i}>
                        <MimaText variant="smallBold" mb={0.5} mt={2}>
                          {price?.type === "LOCAL"
                            ? "Local "
                            : "International "}
                          Pricing
                        </MimaText>
                        <div className="modal__beside__grid">
                          <MimaText labelTitle="Price (RTW)">
                            {amountFormatter(price?.currencyCode).format(
                              price?.withMaterial
                            )}
                          </MimaText>
                          {price?.type === "LOCAL" && (
                            <MimaText labelTitle="Price With Customer Material">
                              {amountFormatter(price?.currencyCode).format(
                                price?.withoutMaterial
                              )}
                            </MimaText>
                          )}
                          <MimaText labelTitle="Promo Price">
                            {amountFormatter(price?.currencyCode).format(
                              price?.onSaleSlashPrice
                            )}
                          </MimaText>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>

      {/* <div>
        
        
        
        
       
        
        
       
        <div>
          <MimaText variant="small" mt={2}>
            Style Collections
          </MimaText>
          {selectedStyleData?.styleCollections.map((item) => (
            <MimaText>{item}</MimaText>
          ))}
        </div>
        {selectedStyleData?.colorsAvailable.length > 1 ? (
          <div>
            <MimaText variant="small" mt={2} mb={1}>
              Colors Available
            </MimaText>
            {selectedStyleData?.colorsAvailable.map((item) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: item?.code,
                    borderRadius: "10PX",
                  }}
                ></div>
                <MimaText>{item?.color}</MimaText>
              </div>
            ))}
          </div>
        ) : (
          <MimaText labelTitle="Colors Available" variant="body" mt={2} mb={1}>
            No color added
          </MimaText>
        )}
       {" "}
        <>
          <MimaText mt={2} mb={1} align="center">
            Style Image
          </MimaText>
          {selectedStyleData?.imageUrls.map((imgUrl, i) => (
            <div key={i} className={styles.img}>
              <img src={imgUrl} alt="Style" width="100%" />
            </div>
          ))}
        </>
      </div> */}
    </Modal>
  );
};

export default ViewMoreStyle;
