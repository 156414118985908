import { useState } from "react";
// import * as yup from "yup";
import constant from "../../../utils/constant";
import { WebsiteController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { handleImageUpload, removeEmptyKeys } from "../../../utils/utils";
import { websiteStore } from "../../../stores";

const useWebsiteConfig = () => {
  const navTitle = {
    menuBar: "Menu bar style",
    homePageBanner: "Home Page Banner",
    aboutUsPage: "About Us Page",
    localShipping: "Local Shipping",
    internationalShipping: "International Shipping",
    refundPolicy: "Refund Policy",
    termsOfService: "Standard Terms of Services",
    faqs: "FAQs Page",
    sizeGuide: "Size Guide",
    maintenanceMode: "Maintenance Mode",
    liveChat: "Live Chat",
    googleTag: "Google Tag",
    pinterestTag: "Pinterest Tag",
  };

  const navData = [
    {
      title: navTitle.menuBar,
    },
    {
      title: navTitle.homePageBanner,
    },
    {
      title: navTitle.aboutUsPage,
    },
    {
      title: navTitle.localShipping,
    },
    {
      title: navTitle.internationalShipping,
    },
    {
      title: navTitle.refundPolicy,
    },
    {
      title: navTitle.termsOfService,
    },
    {
      title: navTitle.faqs,
    },
    {
      title: navTitle.sizeGuide,
    },
    {
      title: navTitle.maintenanceMode,
    },
    {
      title: navTitle.liveChat,
    },
    {
      title: navTitle.googleTag,
    },
    {
      title: navTitle.pinterestTag,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(navData[0]?.title);
  const [editConfig, setEditConfig] = useState("");

  const showConfig = (config) => {
    setSelectedConfig(config);
  };
  const showEditConfig = (config) => {
    setEditConfig(`EDIT ${config}`);
  };
  const closeEditConfig = () => {
    setEditConfig("");
  };

  const { webConfig } = websiteStore;

  const onSubmit = async (values) => {
    setLoading(true);
    let homeBannerUrl = [];
    const file = values.homeBannerImageUrl?.[0];
    if (file) {
      homeBannerUrl = [file.preview];
      if (file.name !== file.preview) {
        homeBannerUrl = await handleImageUpload(values?.homeBannerImageUrl);
      }
    }
    let aboutUsUrl = [];
    const file2 = values.aboutUsImageUrl?.[0];
    if (file2) {
      aboutUsUrl = [file2.preview];
      if (file2.name !== file2.preview) {
        aboutUsUrl = await handleImageUpload(values?.aboutUsImageUrl);
      }
    }

    const payload = {
      tawk: values.tawk,
      googleTag: values.googleTag,
      pinterest: values.pinterest,

      website: {
        homeBanner: {
          imageUrl: homeBannerUrl.length > 0 ? homeBannerUrl[0] : "",
          title: values.website.homeBanner.title,
        },
        collectionsMenuStyle: values.website.collectionsMenuStyle,
        aboutUs: {
          imageUrl: aboutUsUrl.length > 0 ? aboutUsUrl[0] : "",
          text: values.website.aboutUs.text,
        },
        faqs: values.website.faqs.map((faq) => ({
          question: faq.question,
          text: faq.text,
        })),
        sizeGuides: values.website.sizeGuides,

        shipping: {
          local: values.website.shipping.local,
          international: values.website.shipping.international,
        },
        maintenanceMode: values.website.maintenanceMode,
        refundPolicy: values.website.refundPolicy,
        refundPolicyDays: values.website.refundPolicyDays,
        useDefaultRefundPolicy: values.website.useDefaultRefundPolicy,
        termsOfService: values.website.termsOfService,
        useDefaulTermsOfService: values.website.useDefaulTermsOfService,
        privacyPolicy: values.website.privacyPolicy,
        theme: webConfig?.website?.theme,
      },
    };

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await WebsiteController.configureWebsite(payload);

    setLoading(false);
    if (status === constant.Success) {
      closeEditConfig();
      MimaToastUtil.success({
        message: constant.Success,
      });
      return;
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    navData,
    loading,
    setLoading,
    onSubmit,
    selectedConfig,
    showConfig,
    navTitle,
    editConfig,
    showEditConfig,
    closeEditConfig,
  };
};

export default useWebsiteConfig;
