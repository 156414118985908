import React, { useEffect, useState } from "react";
import { WebsiteController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import * as yup from "yup";
import { handleImageUpload, removeEmptyKeys } from "../../../utils/utils";
import { StyleStep1, StyleStep2, StyleStep3 } from "../AddStyleSteps";
import { businessStore } from "../../../stores";

const useUploadStyleLogic = ({ closeModal, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [hashTags, setHashTags] = useState([]);

  useEffect(() => {
    const fetchCollections = async () => {
      await WebsiteController.getCollections();
    };
    fetchCollections();
  }, []);

  //New style break

  const [currentStep, setCurrentStep] = useState(1);

  const stepOneValidation = yup.object({
    name: yup.string().required("Enter Style Name"),
    gender: yup.string().required("Gender is required"),
    type: yup.string().required("Product type is required"),
    styleCollections: yup.array().when("createCollections", {
      is: false,
      then: yup
        .array()
        .min(
          1,
          "Please select at least one collection or click Yes to create collection."
        )
        .required("Style Collection is required."),
    }),
    newCollections: yup.array().when("createCollections", {
      is: true,
      then: yup
        .array()
        .min(1, "Please Add at least one collection")
        .required("Style Collection is required."),
    }),
  });

  const stepTwoValidation = yup.object({
    type: yup.string().required("Product type is required"),
    sellAbroad: yup.boolean().required("Sell Abroad is required"),
    pricingModel: yup
      .string()
      .oneOf(["FIXED_PRICE", "BESPOKE"])
      .required("Pricing model is required"),
    variations: yup.array().of(
      yup.object({
        name: yup.string().required("Variation Name is required"),
        sizes: yup.array().of(yup.string()),
        accessories: yup.array().of(yup.string()),
        customColors: yup.array().when("addCustomColor", {
          is: true,
          then: yup
            .array()
            .min(
              1,
              "Please Add at least one Custom color or Select NO for add custom colors above"
            )
            .required("Custom color is required."),
        }),
        durationInMinutes: yup.number().when(["$type"], {
          is: (type) => {
            return type === "SERVICE";
          },
          then: yup
            .number()
            .min(1, "Please Add how many minutes a session of this service is ")
            .required("Service duration is required."),
          otherwise: yup.number().optional(),
        }),
        local: yup.object({
          withoutMaterial: yup.number().optional(),
          currencyCode: yup.string().required("Currency code is required"),
          onSaleSlashPrice: yup.number().optional(),
          withMaterial: yup.number().when(["$sellAbroad", "$pricingModel"], {
            is: (sellAbroad, pricingModel) =>
              sellAbroad === false && pricingModel === "FIXED_PRICE",
            then: yup
              .number()
              .min(1, "Price (Ready to wear) cannot be zero (0)")
              .required("Price (Ready to wear) is required"),
            otherwise: yup.number().optional(),
          }),
        }),
        international: yup.object({
          currencyCode: yup.string().when(["$sellAbroad", "$pricingModel"], {
            is: (sellAbroad, pricingModel) =>
              sellAbroad === true && pricingModel === "FIXED_PRICE",
            then: yup
              .string()
              .required("International currency code is required"),
            otherwise: yup.string(),
          }),
          withMaterial: yup.number().when(["$sellAbroad", "$pricingModel"], {
            is: (sellAbroad, pricingModel) =>
              sellAbroad === true && pricingModel === "FIXED_PRICE",
            then: yup.number().required("International price is required"),
            otherwise: yup
              .number()
              .min(0, "International price  must be 0 or more"),
          }),
          onSaleSlashPrice: yup
            .number()
            .min(0, "International Sales must be 0 or more"),
        }),
      })
    ),
  });

  const stepThreeValidation = yup.object({
    type: yup.string().required("Product type is required"),
    imageUrls: yup
      .array()
      .min(1, "Atleast 1 image is required")
      .required("Images are required."),
    daysRequiredForDelivery: yup.object({
      early: yup
        .number()
        .min(1, "Delivery date cannot be less than 1")
        .required("Early delivery date is required"),
      late: yup
        .number()
        .min(
          yup.ref("early"),
          "Late delivery date cannot be less than early delivery"
        )
        .required("Late delivery date is required"),
    }),
    availableWindow: yup.object({
      startHour: yup.number().when("$type", {
        is: "SERVICE",
        then: yup
          .number()
          .min(1, "Start time cannot be less than 1:00HRS")
          .max(24, "Start time cannot be more than 24:00HRS")
          .required("Start time is required"),
        otherwise: yup.number(),
      }),
      endHour: yup.number().when("$type", {
        is: "SERVICE",
        then: yup
          .number()
          .min(1, "End time cannot be less than 1:00HRS")
          .max(24, "End time cannot be more than 24:00HRS")
          .required("End time is required"),
        otherwise: yup.number(),
      }),
    }),
    availableDays: yup.array().when("$type", {
      is: "SERVICE",
      then: yup
        .array()
        .min(1, "Select at least 1 day your service is available")
        .required("Available days are required"),
      otherwise: yup.array(),
    }),
  });

  const validationSchema = () => {
    switch (currentStep) {
      case 1:
        return stepOneValidation;
      case 2:
        return stepTwoValidation;
      case 3:
        return stepThreeValidation;
      default:
        return stepOneValidation;
    }
  };

  const onSubmit = async (values) => {
    if (currentStep < 3) {
      return setCurrentStep(currentStep + 1);
    }

    if (values.imageUrls?.length < 1) {
      return MimaToastUtil.error({
        message: "Kindly Add atleast 1 image ",
      });
    }

    setLoading(true);

    let payload;

    let urls = [];
    if (values.imageUrls.length > 0) {
      urls = await handleImageUpload(values.imageUrls);
    }

    const unifiedCollections = [
      ...values.styleCollections,
      ...values.newCollections,
    ];

    const updatedVariations = values.variations.map(
      ({
        customColors,
        colorsAvailable,
        international,
        local,
        addCustomColor,
        ...rest
      }) => {
        const customColorsObjects = customColors?.map((color) => ({
          color,
          type: "CUSTOM",
        }));

        return {
          ...rest,
          ...(values.sellAbroad && values.pricingModel !== "BESPOKE"
            ? { international }
            : {}),
          ...(values.pricingModel !== "BESPOKE" ? { local } : {}),
          ...(colorsAvailable?.length > 0 || customColorsObjects?.length > 0
            ? {
                colorsAvailable: [
                  ...(colorsAvailable || []),
                  ...(customColorsObjects || []),
                ].filter((item) => item && Object.keys(item).length > 0),
              }
            : {}),
        };
      }
    );

    const prePayload = {
      ...values,
      variations: updatedVariations,
    };

    let typeUpdatedVariations;

    if (values.type === "PRODUCT") {
      delete prePayload.availableDays;
      delete prePayload.availableWindow;

      typeUpdatedVariations = prePayload.variations.map(
        ({ durationInMinutes, ...rest }) => rest
      );
    }

    if (values.type === "SERVICE") {
      typeUpdatedVariations = [...prePayload.variations];
    }

    payload = {
      ...prePayload,
      variations: typeUpdatedVariations,
      imageUrls: urls,
      styleCollections: unifiedCollections,
    };

    delete payload.createCollections;
    delete payload.hasVariations;
    delete payload.newCollections;
    delete payload.sellAbroad;

    if (!values.description) {
      delete payload.description;
    }

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await WebsiteController.uploadStyle(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const oldInitialValues = {
    name: "",
    pricingModel: "FIXED_PRICE",
    parts: [],
    imageUrls: [],
    hashTags: [],
    topView: false,
    colorsAvailable: [],
    sizes: [],
    styleCollections: [],
    createCollections: false,
    addDeliveryDuration: false,
    daysRequiredForDelivery: {
      early: 0,
      late: 0,
    },
    local: {
      withoutMaterial: 0,
      // currencyCode: localCurrency,
      onSaleSlashPrice: 0,
      withMaterial: 0,
    },
    international: {
      currencyCode: "",
      withMaterial: 0,
      onSaleSlashPrice: 0,
    },
    description: "",
    gender: "MALE",
    addBookingLinkUrl: false,
    bookingLinkUrl: "",
    addCustomColor: false,
    customColors: [],
    accessories: [],
  };

  const initialValues = {
    name: "",
    pricingModel: "FIXED_PRICE",
    imageUrls: [],
    topView: false,
    createCollections: false,
    styleCollections: [],
    newCollections: [],
    daysRequiredForDelivery: {
      early: 1,
      late: 1,
    },
    type: "PRODUCT",
    availableWindow: {
      startHour: 0,
      endHour: 0,
    },
    availableDays: [],
    bestSelling: false,
    variations: [
      {
        sizes: [],
        name: "",
        isSoldOut: false,
        accessories: [],
        addCustomColor: false,
        customColors: [],
        colorsAvailable: [
          {
            color: "",
            type: "",
            code: "",
          },
        ],
        location: "",
        local: {
          withoutMaterial: 0,
          currencyCode: businessStore.localCurrency, //Temporary
          onSaleSlashPrice: 0,
          withMaterial: 0,
        },
        durationInMinutes: 0,
        international: {
          currencyCode: "",
          withMaterial: 0,
          onSaleSlashPrice: 0,
        },
      },
    ],
    hasVariations: false,
    description: "",
    gender: "",
    sellAbroad: false,
  };

  const renderStep = (
    currentStep,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  ) => {
    switch (currentStep) {
      case 1:
        return (
          <StyleStep1
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <StyleStep2
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            // setIsDisabled={setIsDisabled}
          />
        );
      case 3:
        return (
          <StyleStep3
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        );
      default:
        return (
          <StyleStep1
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        );
    }
  };

  return {
    validationSchema,
    onSubmit,
    loading,
    hashTags,
    setHashTags,
    initialValues,
    currentStep,
    setCurrentStep,
    renderStep,
  };
};

export default useUploadStyleLogic;
