import React, { useMemo } from "react";
import {
  AppShell,
  MimaButton,
  MimaOptionMenu,
  MimaTable,
  MimaText,
} from "../../components";
import constant from "../../utils/constant";
import styles from "../../assets/styles/website/website.module.scss";
import useStylesLogic from "./useLogic/useStylesLogic";
import { AnimatePresence } from "framer-motion";
import UploadStyle from "./UploadStyle";
import { amountFormatter } from "../../utils/utils";
import EditStyle from "./EditStyle";
import DeleteStyle from "./DeleteStyle";
import ViewMoreStyle from "./ViewMoreStyle";

const Styles = () => {
  const {
    limit,
    currentPage,
    setCurrentPage,
    onLimitChange,
    setSearchQuery,
    handleOptionSelect,
    isLoading,
    isFetching,
    data,
    uploadStyleModal,
    goToUploadStyle,
    closeModal,
    refetch,
    editStyleModal,
    deleteStyleModal,
    viewMoreStyleModal,
  } = useStylesLogic();
  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.styles || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "Image",
      Cell: ({ cell }) => {
        const imageUrls = cell.row.original?.imageUrls;
        return (
          <div>
            <img src={imageUrls?.[0]} alt="Style" />
          </div>
        );
      },
    },
    {
      Header: "Style Name",
      accessor: "name",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Pricing Model",
      accessor: "pricingModel",
    },
    {
      Header: "Local Prices",
      accessor: "sellingPrices",
      Cell: ({ cell }) => {
        const firstVariation = cell?.row?.original?.variations?.[0];
        const pricingModel = cell.row.original.pricingModel;
        const local = firstVariation?.sellingPrices
          .filter((x) => x?.type === "LOCAL")
          .map((x) => x)[0];

        const rtw = amountFormatter(local?.currencyCode).format(
          local?.withMaterial
        );
        const withoutMaterial = amountFormatter(local?.currencyCode).format(
          local?.withoutMaterial
        );

        const onSaleSlashPrice = amountFormatter(local?.currencyCode).format(
          local?.onSaleSlashPrice
        );

        return (
          <div>
            {pricingModel === "FIXED_PRICE" ? (
              <>
                <MimaText
                  labelTitle="Price / RTW"
                  labelColor="var(--color-dark)"
                  variant="smallBold"
                >
                  {rtw}
                </MimaText>
                <MimaText
                  labelTitle="Without Material"
                  labelColor="var(--color-dark)"
                  variant="smallBold"
                >
                  {withoutMaterial}
                </MimaText>

                <MimaText
                  labelTitle="Promo Price"
                  labelColor="var(--color-dark)"
                  variant="smallBold"
                >
                  {onSaleSlashPrice}
                </MimaText>
              </>
            ) : (
              <span>NA</span>
            )}
          </div>
        );
      },
    },
    {
      Header: "International Price",
      Cell: ({ cell }) => {
        const firstVariation = cell?.row?.original?.variations?.[0];
        const pricingModel = cell.row.original.pricingModel;
        const international = firstVariation?.sellingPrices
          .filter((x) => x?.type === "INTERNATIONAL")
          .map((x) => x)[0];

        const rtw = amountFormatter(international?.currencyCode).format(
          international?.withMaterial
        );
        const onSaleSlashPrice = amountFormatter(
          international?.currencyCode
        ).format(international?.onSaleSlashPrice);

        return (
          <div>
            {pricingModel === "FIXED_PRICE" && international ? (
              <>
                <MimaText
                  labelTitle="Price"
                  labelColor="var(--color-dark)"
                  variant="smallBold"
                >
                  {rtw}
                </MimaText>
                <MimaText
                  labelTitle="Promo Price"
                  labelColor="var(--color-dark)"
                  variant="smallBold"
                >
                  {onSaleSlashPrice}
                </MimaText>
              </>
            ) : (
              <span>NA</span>
            )}
          </div>
        );
      },
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const stylesDetails = cell.row.original;
        return (
          <MimaOptionMenu
            options={[
              {
                value: "View More",
                label: "View more",
              },
              {
                value: "Edit Style",
                label: "Edit Style",
              },
              {
                value: "Delete Style",
                label: "Delete Style",
              },
            ]}
            onOptionSelected={(option) => {
              handleOptionSelect(stylesDetails, option);
            }}
          />
        );
      },
    },
  ];
  return (
    <AppShell pageTitle="Styles">
      <div className={styles.upperBtn}>
        <MimaButton
          title="Upload Style"
          width={15}
          onClick={goToUploadStyle}
          buttonColor={"var(--color-dark)"}
          titleColor="var(--color-white)"
          mb={1}
          mt={1}
        />
      </div>
      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Style"
        searchVariant="wide"
        totalItems={data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={isLoading}
        isFetching={isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />

      {(uploadStyleModal ||
        editStyleModal ||
        deleteStyleModal ||
        viewMoreStyleModal) && (
        <div className="modal">
          <AnimatePresence>
            {uploadStyleModal && (
              <UploadStyle closeModal={closeModal} refetch={refetch} />
            )}
            {editStyleModal && (
              <EditStyle closeModal={closeModal} refetch={refetch} />
            )}
            {deleteStyleModal && (
              <DeleteStyle closeModal={closeModal} refetch={refetch} />
            )}
            {viewMoreStyleModal && <ViewMoreStyle closeModal={closeModal} />}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default Styles;
