import { useEffect, useMemo, useState } from "react";
import {
  BeautifyThemeImg,
  DefaultThemeImg,
  LuxeFlexThemeImg,
} from "../../../assets/img/ImgList";
import { businessStore, userStore, websiteStore } from "../../../stores";
import { WebsiteController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";

const useThemeConfig = () => {
  const [loading, setLoading] = useState(false);

  const themes = useMemo(() => {
    return [
      {
        name: "Default",
        key: "DEFAULT",
        image: DefaultThemeImg,
      },
      {
        name: "Beautify",
        key: "UPGRADE",
        image: BeautifyThemeImg,
      },
      {
        name: "Luxe Flex",
        key: "LUXEFLEX",
        image: LuxeFlexThemeImg,
      },
    ];
  }, []);

  const [activeTheme, setActiveTheme] = useState(themes[0]);

  const changeTheme = (theme) => {
    setActiveTheme(theme);
  };

  const { business, settings } = businessStore;
  const { token } = userStore;

  const currentTheme = useMemo(() => {
    const preloadTheme = settings?.website?.theme;
    if (preloadTheme) {
      return themes.filter((x) => x.key === preloadTheme)[0];
    }

    return themes[0];
  }, [settings, themes]);

  useEffect(() => {
    if (currentTheme?.key) {
      setActiveTheme(currentTheme);
    }
  }, [currentTheme]);

  const customWebHandler = () => {
    const editLink = `${business?.storeFrontUrl}?edit=true&token=${token}`;
    window.open(editLink, "_blank");
  };

  const { webConfig } = websiteStore;

  const setThemeHandler = async (theme) => {
    setLoading(true);
    const payload = {
      tawk: webConfig.tawk,
      website: {
        ...webConfig?.website,
        theme: theme,
      },
    };

    payload.website.faqs.forEach((faq) => {
      delete faq._id;
    });

    const { status, errorMessage } =
      await WebsiteController.configureWebsite(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
      setLoading(false);
    }
  };

  return {
    themes,
    activeTheme,
    changeTheme,
    customWebHandler,
    currentTheme,
    setThemeHandler,
    loading,
  };
};

export default useThemeConfig;
